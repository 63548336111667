import FrontendConfig from "../assets/config.js";

export const viewDetailsConfig = () => {
    const config = {
        "ymtechone": {
            redirectUrl: "https://ymtech.com",
        },
        "silentbreach": {
            redirectUrl: "https://silentbreach.com",
        },
        "schur": {
            redirectUrl: "https://schur.com",
            qrLogo: require("../envs/schur/assets/img/schur-logo.png"),
        },
        "cfcsea": {
            redirectUrl: "https://www.centreforcybersecurity.com",
        },
        "natan": {
            redirectUrl: "https://natan.com.au",
        },
        "centrevision": {
            redirectUrl: "https://centrevision.com.au",
        },
        "ahp": {
            redirectUrl: "https://aushp.com.au",
        },
        "prod": {
            redirectUrl: "https://sharepass.com",
            qrLogo: "/assets/img/SP.png",
            createAccount: true,
        },
        "tst": {
            redirectUrl: "https://sharepass.com",
            qrLogo: "/assets/img/SP.png",
            createAccount: true,
        },
        "staging": {
            redirectUrl: "https://sharepass.com",
            qrLogo: "/assets/img/SP.png",
            createAccount: true,
        },
        "kbi": {
            redirectUrl: "https://kbi.media",
        }
    };

    return config[FrontendConfig.env];
};